import React, { useMemo } from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface Props<T extends Record<string, any>, IdType> {
	width?: string | undefined;
	placeholder?: string;
	elements: T[];
	value: IdType;
	id: string;
	allowClear?: boolean;
	onChange: (id: IdType) => void;
	buildElement: (element: T) => any;
}

function SearchSelector<T, IdType>({
	placeholder,
	elements,
	value,
	id,
	allowClear,
	onChange,
	buildElement,
	width,
}: Props<T, IdType>) {
	const selectedElement = useMemo(() => {
		return elements?.find((element: T) => element[id] == value);
	}, [elements, value]);

	const options = useMemo(() => {
		return elements?.map((element: T) => {
			return (
				<Option key={element[id]} value={element[id]}>
					{buildElement(element)}
				</Option>
			);
		});
	}, [elements]);

	return (
		<Select
			showSearch
			allowClear={allowClear ?? false}
			placeholder={placeholder}
			style={{ width: width ?? 'auto' }}
			value={selectedElement && buildElement(selectedElement)}
			onChange={(id) => onChange(id)}
			optionFilterProp="children"
			filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
			filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
		>
			{options}
		</Select>
	);
}

export default SearchSelector;
