import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment, { isDate } from 'moment';
import { Activity, Process, User } from 'types';
import { Filters, filtersToQuery } from './components/filters';
import { q } from 'utils';

export const useFilters = ({
	processes,
	activities,
	workers,
}: {
	processes: Process[] | undefined;
	activities: Activity[] | undefined;
	workers: User[] | undefined;
}) => {
	const history = useHistory();
	const query = q.parse(history.location.search);

	const process = useMemo(() => {
		if (!processes) {
			return undefined;
		}

		const processId = query['process_id'];
		if (typeof processId !== 'string') {
			return processes[0];
		}

		let parsedProcessId = parseInt(processId);
		if (isNaN(parsedProcessId)) {
			return processes[0];
		}

		return processes.find((process) => process.prc_id === processId);
	}, [JSON.stringify(processes), query['process_id']]);

	const selectedActivities = useMemo(() => {
		if (!activities) {
			return undefined;
		}

		const activityIds = query['activity_id'];
		if (typeof activityIds === 'string') {
			const activityId = parseInt(activityIds);
			if (isNaN(activityId)) {
				return undefined;
			}

			const activity = activities.find((activity) => activity.id === activityId);
			if (!activity) {
				return undefined;
			}

			return [activity];
		}

		if (Array.isArray(activityIds)) {
			const selectedActivities: Activity[] = [];

			for (const activityId of activityIds) {
				if (typeof activityId !== 'string') {
					continue;
				}

				const parsed = parseInt(activityId);
				if (isNaN(parsed)) {
					continue;
				}

				const activity = activities.find((activity) => activity.id === parsed);
				if (!activity) {
					continue;
				}

				selectedActivities.push(activity);
			}

			if (selectedActivities.length === 0) {
				return undefined;
			}

			return selectedActivities;
		}

		return undefined;
	}, [JSON.stringify(activities), query['activity_id']]);

	const after = useMemo(() => {
		const after = query['after'];
		if (typeof after !== 'string') {
			return undefined;
		}

		const date = new Date(after);
		if (!isDate(date) || isNaN(date.getTime())) {
			return undefined;
		}

		return moment(date);
	}, [query['after']]);

	const before = useMemo(() => {
		const before = query['before'];
		if (typeof before !== 'string') {
			return undefined;
		}

		const date = new Date(before);
		if (!isDate(date) || isNaN(date.getTime())) {
			return undefined;
		}

		return moment(date);
	}, [query['before']]);

	const workerIds = useMemo(() => {
		const workerIds = query['worker_id'];
		if (typeof workerIds === 'string') {
			return [workerIds];
		}

		if (Array.isArray(workerIds)) {
			return workerIds.filter((id) => typeof id === 'string') as string[];
		}

		return undefined;
	}, [query['worker_id']]);

	const selectedWorkers = useMemo(() => {
		if (!workers || !workerIds) {
			return undefined;
		}

		const selectedWorkers: User[] = [];
		for (const workerId of workerIds) {
			const parsedWorkerId = parseInt(workerId);
			if (isNaN(parsedWorkerId)) {
				continue;
			}

			const worker = workers.find((worker) => worker.id === parsedWorkerId);
			if (!worker) {
				continue;
			}

			selectedWorkers.push(worker);
		}

		if (selectedWorkers.length === 0) {
			return undefined;
		}

		return selectedWorkers;
	}, [JSON.stringify(workers), workerIds]);

	const selectedFields = useMemo(() => {
		const fields = query['field'];
		if (typeof fields === 'string') {
			return new Set<string>([fields]);
		}

		if (Array.isArray(fields)) {
			return new Set<string>(fields);
		}

		return undefined;
	}, [query['field']]);

	const commentsSearch = useMemo(() => {
		const commentsSearch = query['comments_search'];
		if (typeof commentsSearch !== 'string') {
			return undefined;
		}

		if (commentsSearch.length === 0) {
			return undefined;
		}

		return commentsSearch;
	}, [query['comments_search']]);

	const withoutReport = useMemo(() => {
		const withoutReport = query['without_report'];
		if (typeof withoutReport === 'string') {
			return withoutReport === 'true';
		}

		return false;
	}, [query['without_report']]);

	const onFiltersChange = (filters: Filters) => {
		const newQuery = filtersToQuery(filters);

		history.replace({
			search: q.stringify(newQuery),
		});
	};

	return {
		process,
		selectedActivities,
		after,
		before,
		selectedWorkers,
		selectedFields,
		commentsSearch,
		withoutReport,
		onFiltersChange,
	};
};
