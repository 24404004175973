import User from 'models/user';
import { Activity, EkoWork, Process, User as NewUser, UserLevel } from 'types';
import Report from './models/report';
import TotalActivitiesAmountsPerProcess from './models/totalActivitiesAmountsPerProcess';
import TotalActivitiesAmountsPerReport from './models/totalActivitiesAmountsPerReport';
import { Query, QueryObject } from './utils';
import { Delegation } from 'models/delegation';
import moment from 'moment';

const serverUrl = process.env['API'];
const apiUrl = serverUrl + '/api';

const send = async (method: string, endpoint: string, data?: {}, query?: QueryObject) => {
	const response = await fetch(`${apiUrl}/${endpoint}${query ? '?' + Query.encode(query) : ''}`, {
		method: method,
		headers: {
			'Content-Type': data ? 'application/json' : 'plain/text',
		},
		body: data ? JSON.stringify(data) : null,
		credentials: 'include',
	});

	if (endpoint !== 'logout' && response.status === 403) {
		window.location.href = '/logout';
		return {};
	}

	if (response.headers.get('content-type')?.match(/application\/json/g)) {
		if (response.status !== 200) throw await response.json();
		return await response.json();
	} else if (response.headers.get('content-type')?.match(/application\/pdf/g)) {
		return await response.blob();
	} else {
		if (response.status !== 200) throw await response.text();
		return await response.text();
	}
};

export interface EkoWorksPatchDto {
	userIds?: number[];
	date?: moment.Moment;
	field?: string;
	activityId?: number;
	amount?: number;
	comments?: string | null;
}

export interface ActivitiesPatchDto {
	name?: string;
	unit?: string;
	contracted?: number;
	unitPrice?: number;
	isReported?: boolean;
}

export type AddEkoWorkPhotosDto = {
	data: string;
	extension: string;
}[];

export default {
	getAssetUrl: (asset: string) => `${serverUrl}/assets/${asset}`,

	login: async (login: string, pass: string) => {
		try {
			return await send('POST', 'login', { login, pass });
		} catch (e) {
			if (e.status === 403) throw 'Niepoprawne dane logowania';

			throw `Nieznany błąd ${e.message || e.error || e}`;
		}
	},

	managerLogin: async (token: string, refreshToken: string) => {
		try {
			return await send('POST', 'login', { token, refreshToken });
		} catch (e) {
			if (e.status === 403) throw 'Niepoprawne dane logowania';

			throw `Nieznany błąd ${e.message || e.error || e}`;
		}
	},

	users: {
		getAll: async (): Promise<User[]> => {
			try {
				const result = await send('GET', 'users');

				return result.map(User.fromJson);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchPassword: async (id: number) => {
			try {
				return await send('PATCH', `users/${id}`, { pass: null });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchHasWorks: async (id: number, hasWorks: boolean) => {
			try {
				return await send('PATCH', `users/${id}`, { has_works: hasWorks });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number, unblock: boolean = false) => {
			try {
				return await send('DELETE', `users/${id}`, undefined, { unblock: unblock ? '1' : undefined });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	newUsers: {
		getAll: async () => {
			try {
				const users = await send('GET', 'v2/users');

				return users as NewUser[];
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	user: (id: number) => ({
		patch: async (data: { level?: UserLevel }) => {
			try {
				const result = await send('PATCH', `v2/users/${id}`, data);

				return result as NewUser;
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		resetPassword: async () => {
			try {
				const result = await send('POST', `v2/users/${id}/reset-password`);

				return result.token as string;
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (unblock: boolean = false) => {
			try {
				return await send('DELETE', `v2/users/${id}`, undefined, { unblock: unblock ? '1' : undefined });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	}),

	offers: {
		getAll: async (cover: string, level: string) => {
			try {
				return await send('GET', `offers`, undefined, { cover, level });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		get: async (id: number) => {
			try {
				return await send('GET', `offers/${id}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		put: async ({
			level,
			file,
			cover,
			type,
			name,
			folder_id,
		}: {
			level: number;
			file: string;
			cover: string;
			type: string;
			name: string;
			folder_id: number;
		}) => {
			try {
				return await send('PUT', 'offers', { level, file, cover, type, name, folder_id });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number) => {
			try {
				return await send('DELETE', `offers/${id}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	notifications: {
		put: async ({ title, content }: { title: string; content: string }) => {
			try {
				return await send('POST', 'notifications/send', { title, content });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		getLast: async () => {
			try {
				return await send('GET', 'notifications');
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	pads: {
		put: async ({ name, type, price, usage }: { name: string; type: string; price: number; usage: number }) => {
			try {
				return await send('PUT', 'pads', { name, type, price, usage });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		getAll: async () => {
			try {
				return await send('GET', 'pads');
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number) => {
			try {
				return await send('DELETE', `pads/${id}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patch: async (id: number, { name, price, usage }: { name?: string; price?: number; usage?: number }) => {
			try {
				return await send('PATCH', `pads/${id}`, { name, price, usage });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	liquids: {
		put: async ({
			name,
			quantity,
			unit,
			price,
			liquid,
			water,
		}: {
			name: string;
			quantity: number;
			unit: string;
			price: number;
			liquid: number;
			water: number;
		}) => {
			try {
				return await send('PUT', 'liquids', { name, quantity, unit, price, liquid, water });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		getAll: async () => {
			try {
				return await send('GET', 'liquids');
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number) => {
			try {
				return await send('DELETE', `liquids/${id}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patch: async (
			id: number,
			{
				name,
				quantity,
				unit,
				price,
				liquid,
				water,
			}: { name?: string; quantity?: number; unit?: string; price?: number; liquid?: number; water?: number }
		) => {
			try {
				return await send('PATCH', `liquids/${id}`, { name, quantity, unit, price, liquid, water });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	folders: {
		getAll: async () => {
			try {
				return await send('GET', 'folders');
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		put: async (name: string) => {
			try {
				return await send('PUT', 'folders', { name });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patch: async (id: number, { name, activate }: { name: string; activate: boolean }) => {
			try {
				return await send('PATCH', `folders/${id}`, { name, activate });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number) => {
			try {
				return await send('DELETE', `folders/${id}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	employees: {
		getAll: async () => {
			try {
				return await send('GET', 'employees');
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		put: async (id: number, name: string, surname: string) => {
			try {
				return await send('PUT', 'employees', { ext_id: id, login: Date.now(), name, surname });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	hours: {
		getAll: async (after?: number, before?: number, user_id?: number, process_id?: number, action_id?: number) => {
			try {
				const query = Query.encode({
					after: after?.toString(),
					before: before?.toString(),
					user_id: user_id?.toString(),
					process_id: process_id?.toString(),
					action_id: action_id?.toString(),
				});

				return await send('GET', `hours?${query}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patch: async (id: number, accepted: boolean) => {
			try {
				return await send('PATCH', `hours/${id}`, { accepted });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchProcess: async (id: number, processId: number) => {
			try {
				return await send('PATCH', `hours/${id}`, { processId });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchAction: async (id: number, actionId: number) => {
			try {
				return await send('PATCH', `hours/${id}`, { actionId });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchBonus: async (id: number, bonus: number) => {
			try {
				return await send('PATCH', `hours/${id}`, { bonus });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchAdditionalBonus: async (id: number, bonus: boolean) => {
			try {
				return await send('PATCH', `hours/${id}`, { additional_bonus: bonus ? 1 : 0 });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchSpecialBonus: async (id: number, bonus: boolean) => {
			try {
				return await send('PATCH', `hours/${id}`, { special_bonus: bonus ? 1 : 0 });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number) => {
			try {
				return await send('DELETE', `hours/${id}`);
			} catch (e) {
				throw `Nieznany błąd ${JSON.stringify(e.message || e.error || e)}`;
			}
		},

		edit: async (id: number, start: number, end: number) => {
			try {
				return await send('PATCH', `hours/${id}`, { start, end });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		send: async (id: number, sent: boolean) => {
			try {
				return await send('PATCH', `hours/${id}`, { sent });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		create: async (action_id: number, process_id: number, start: number, end: number, worker_id: number) => {
			try {
				return await send('PUT', `hours`, { action_id, process_id, start, end, worker_id });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	processes: {
		getAll: async (): Promise<Process[]> => {
			try {
				return await send('GET', `processes`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	process: (id: number) => ({
		patch: async (params: { isDelegate?: boolean }) => {
			try {
				return await send('PATCH', `processes/${id}`, params);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	}),

	actions: {
		getAll: async () => {
			try {
				return await send('GET', `actions`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	works: {
		getAll: async (after?: number, before?: number, user_id?: number, process_id?: number, action_id?: number) => {
			try {
				const query = Query.encode({
					after: after?.toString(),
					before: before?.toString(),
					user_id: user_id?.toString(),
					process_id: process_id?.toString(),
					action_id: action_id?.toString(),
				});

				return await send('GET', `works?${query}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patch: async (id: number, accepted: boolean) => {
			try {
				return await send('PATCH', `works/${id}`, { accepted });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		edit: async (id: number, date: number) => {
			try {
				return await send('PATCH', `works/${id}`, { date });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchProcess: async (id: number, processId: number) => {
			try {
				return await send('PATCH', `works/${id}`, { processId });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchAction: async (id: number, actionId: number) => {
			try {
				return await send('PATCH', `works/${id}`, { actionId });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patchAmount: async (id: number, amount: number) => {
			try {
				return await send('PATCH', `works/${id}`, { amount });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		send: async (id: number, sent: boolean) => {
			try {
				return await send('PATCH', `works/${id}`, { sent });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		create: async (action_id: number, process_id: number, date: number, amount: number, worker_id: number) => {
			try {
				return await send('PUT', `works`, { action_id, process_id, date, amount, worker_id });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number) => {
			try {
				return await send('DELETE', `works/${id}`);
			} catch (e) {
				throw `Nieznany błąd ${JSON.stringify(e.message || e.error || e)}`;
			}
		},
	},

	ekoWorks: {
		getAll: async ({
			processId,
			activityIds,
			after,
			before,
			reportId,
			userIds,
		}: {
			processId?: string | undefined;
			activityIds?: number[] | undefined;
			after?: moment.Moment | undefined;
			before?: moment.Moment | undefined;
			reportId?: number | undefined;
			userIds?: number[] | undefined;
		}): Promise<EkoWork[]> => {
			try {
				return await send('GET', 'ekoWorks', undefined, {
					process_id: processId?.toString(),
					activity_ids: activityIds?.join(','),
					after: after && (after.unix() * 1000).toString(),
					before: before && (before.unix() * 1000).toString(),
					report_id: reportId?.toString(),
					user_ids: userIds?.join(','),
				});
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		get: async (id: number): Promise<EkoWork> => {
			try {
				return await send('GET', `ekoWorks/${id}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		add: async (
			userIds: number[],
			processId: number,
			activityId: number,
			field: string,
			date: moment.Moment,
			amount: number,
			comments: string | null,
			photos: { data: string; extension: string }[]
		): Promise<EkoWork> => {
			try {
				return await send('PUT', 'ekoWorks', {
					user_ids: userIds,
					process_id: processId,
					activity_id: activityId,
					date: date.unix() * 1000,
					field,
					amount,
					comments,
					photos,
				});
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patch: async (id: number, dto: EkoWorksPatchDto): Promise<EkoWork> => {
			try {
				return await send('PATCH', `ekoWorks/${id}`, {
					...dto,
					date: dto.date ? dto.date.unix() * 1000 : undefined,
				});
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number): Promise<boolean> => {
			try {
				const response = await send('DELETE', `ekoWorks/${id}`);

				return response.success ?? false;
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		addPhotos: async (id: number, dto: AddEkoWorkPhotosDto): Promise<EkoWork> => {
			try {
				return await send('POST', `ekoWorks/${id}/photos`, dto);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		deletePhoto: async (id: number, photoName: string): Promise<EkoWork> => {
			try {
				return await send('DELETE', `ekoWorks/${id}/photos/${photoName}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	activities: {
		getAll: async ({ processId }: { processId?: number | null } = {}) => {
			try {
				const result = await send('GET', 'activities', undefined, { process_id: processId?.toString() });

				return result as Activity[];
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		put: async ({
			name,
			processId,
			unit,
			contracted,
			unitPrice,
			isReported,
		}: {
			name: string;
			processId: number;
			unit: string;
			contracted: number;
			unitPrice: number;
			isReported: boolean;
		}) => {
			try {
				const response = await send('PUT', 'activities', {
					name,
					processId: parseInt(`${processId}`),
					unit,
					contracted,
					unitPrice,
					isReported,
				});

				return response as Activity;
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		patch: async (id: number, dto: ActivitiesPatchDto) => {
			try {
				const response = await send('PATCH', `activities/${id}`, dto);

				return response as Activity;
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number): Promise<boolean> => {
			try {
				const response = await send('DELETE', `activities/${id}`);

				return response.success ?? false;
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	reports: {
		getAll: async ({ process_id }: { process_id?: string | null } = {}): Promise<Report[]> => {
			try {
				const json = await send('GET', `reports`, undefined, { process_id: process_id?.toString() });

				return json.map(Report.fromJson);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		add: async ({
			processId,
			after,
			before,
			number,
			scope,
			mocked,
			notices,
			generated,
		}: {
			processId: number;
			after: number;
			before: number;
			number: string;
			scope: string;
			mocked: boolean;
			notices?: string | undefined;
			generated?: number | undefined;
		}): Promise<Report> => {
			try {
				return await send('PUT', `reports`, {
					process_id: parseInt(`${processId}`),
					after: after,
					before: before,
					number: number,
					scope: scope,
					mocked: mocked ? 1 : 0,
					notices: notices,
					generated: generated,
				});
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		getTotalActivitiesPerProcess: async ({ process_id }: { process_id?: string } = {}): Promise<
			TotalActivitiesAmountsPerProcess[]
		> => {
			try {
				return await send('GET', `reports/totalActivitiesPerProcess`, undefined, {
					process_id: process_id?.toString(),
				});
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		getTotalActivitiesPerReport: async ({ report_id }: { report_id?: number } = {}): Promise<
			TotalActivitiesAmountsPerReport[]
		> => {
			try {
				return await send('GET', `reports/totalActivitiesPerReport`, undefined, { report_id: report_id?.toString() });
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},

		delete: async (id: number) => {
			try {
				return await send('DELETE', `reports/${id}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	stats: {
		getAll: async (after: number, before: number) => {
			try {
				return await send('GET', `stats?after=${after}&before=${before}`);
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	prints: {
		ekoWorks: async (query: string) => {
			try {
				const params = Buffer.from(query).toString('base64');

				return (await send('GET', `prints/eko_works?params=${params}`)) as Blob;
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},

	delegations: {
		get: async ({
			after,
			before,
			userIds,
			processId,
		}: {
			after: moment.Moment;
			before: moment.Moment;
			processId?: number | undefined;
			userIds?: number[] | undefined;
		}) => {
			try {
				const response = await send('GET', 'delegations', undefined, {
					after: after.toISOString(),
					before: before.toISOString(),
					process_id: processId?.toString(),
					user_ids: userIds?.map((id) => id.toString()),
				});

				return response as Delegation[];
			} catch (e) {
				throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		},
	},
};
